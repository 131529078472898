import React from 'react';
import { FAQ } from '@/components/FAQ/FAQ';
import { FAQMainPageList } from './FAQMainPageList';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';

type FAQMainPageProps = {};
export const FAQMainPage = ({}: FAQMainPageProps) => {
  const isMdMax = useMediaQuery('(max-width: 768px)');
  return (
    <section className="z-50 bg-white border-t">
      <Container className="md:pt-18-vw pt-10 md:pb-18-vw pb-10 xl:max-w-[74.722vw]">
        <Typography
          as="h3"
          $type="Heading1"
          className="font-formula md:text-center text-left"
          $weight="700"
        >
          Frequently asked questions
        </Typography>
        <FAQ isMobile={isMdMax} list={FAQMainPageList} />
      </Container>
    </section>
  );
};
