import Image from 'next/image';
import React from 'react';

export const REVIEWS_ITEMS = [
  {
    id: 0,
    name: 'Ann Kuss',
    title: (
      <>
        CEO,
        <br />
        Outstaff Your Team
      </>
    ),
    logo: (
      <div className="md:w-[7.639vw] md:h-[3.472vw] w-[100px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/outstaff-your-team.webp"
        />
      </div>
    ),
    description:
      'This AI customer service solution was implemented in just three days, and first month results were stunning — the average resolution time decreased by more than 50%. Long term (we have been a CoSupport client for a year already) — the turnover in the team has improved as our agents were no longer bored resolving repetitive questions. I’m glad we ran into CoSupport AI back in 2023. Recommended!',
    img: '/img/main-page/reviews/kuss.webp',
  },
  {
    id: 1,
    title: (
      <>
        CEO,
        <br />
        Label Your Data
      </>
    ),
    name: 'Karyna Naminas',
    logo: (
      <div className="md:w-[6.736vw] md:h-[3.472vw] w-[87px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/label-your-data.webp"
        />
      </div>
    ),
    description:
      'Our decision-making processes have been transformed with CoSupport BI. It provides our team members with easy access to data insights directly through Slack, helping managers make informed decisions faster. You must try it.',
    img: '/img/main-page/reviews/naminas.webp',
  },
  {
    id: 2,
    title: (
      <>
        Director of Customer Solutions,
        <br />
        Shelterluv
      </>
    ),
    name: 'Matthew Brown',
    logo: (
      <div className="md:w-[7.986vw] md:h-[1.597vw] w-[105px] h-[21px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/shelterluv.webp"
        />
      </div>
    ),
    description:
      "The AI performance has been very good. It handles FAQs and many complex questions well, and the escalated tickets I'm seeing come through are ones I wouldn't expect AI to be able to handle. I’m really happy with CoSupport AI customer service solutions.",
    img: '/img/main-page/reviews/matthew.webp',
  },
  {
    id: 3,
    title: (
      <>
        CEO,
        <br />
        Softorino
      </>
    ),
    name: 'Josh Brown',
    logo: (
      <div className="md:w-[4.375vw] md:h-[3.472vw] w-[56px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/softorino.webp"
        />
      </div>
    ),
    description:
      'It was easy to set up with our Zendesk workspace — this AI for customer service learned everything on its own with just an API key. First month — 69% resolution rate, second — 76%, third — 82%. I think the numbers speak for themselves. We couldn’t achieve even close results with the other 6 companies.',
    img: '/img/main-page/reviews/brown.webp',
  },
  {
    id: 4,
    title: (
      <>
        Account Manager,
        <br />
        SupportYourApp
      </>
    ),
    name: 'Axel Barrionuevo',
    logo: (
      <div className="md:w-[7.569vw] md:h-[3.472vw] w-[98px] h-[45px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/support-your-app.webp"
        />
      </div>
    ),
    description:
      'After launching CoSupport AI Agent, we’ve automated around 7k monthly chats and emails — with AI tools for customer service like this, 80% of our incoming requests are handled automatically now. This allowed us to significantly cut operational costs, saving thousands of $, while maintaining customer satisfaction high. The team’s professionalism exceeded our expectations.',
    img: '/img/main-page/reviews/alex.webp',
  },
  {
    id: 5,
    title: (
      <>
        Project Manager,
        <br />
        ProjectFitter
      </>
    ),
    name: 'Yaro Burgman',
    logo: (
      <div className="md:w-[9.167vw] md:h-[1.458vw] w-[113px] h-[17px]">
        <Image
          width="0"
          height="0"
          sizes="100vw"
          className="w-full h-full object-contain"
          alt="Outstaff Your Team logo"
          src="/img/main-page/reviews/project-fitter.webp"
        />
      </div>
    ),
    description:
      'CoSupport AI streamlined our support operations with its advanced customer service AI tools, automating the resolution process for about 70% of support tickets and shortening the resolution time from hours to minutes.',
    img: '/img/main-page/reviews/yaro.webp',
  },
];
