import React from 'react';
import style from './FAQ.module.css';
import { FAQItem } from './FAQItem/FAQItem';
import { QuestionType } from '@/components/PagesSections/FAQPageComponents/FAQQuestionsVariants';
import { useRouter } from 'next/router';

type FAQProps = {
  className?: string;
  isMobile: boolean;
  rememberOpen?: boolean;
  list: QuestionType[];
};
export const FAQ = ({ isMobile, list, className = '', rememberOpen = false }: FAQProps) => {
  const router = useRouter();

  return (
    <div className={`${className} ${isMobile ? style.containerMobile : style.containerDesktop}`}>
      {list.map((item, index) => {
        return (
          <FAQItem
            rememberOpen={rememberOpen}
            router={router}
            isMobile={isMobile}
            item={item}
            key={`faq-item-${index}`}
          />
        );
      })}
    </div>
  );
};
