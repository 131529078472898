export const DELIVERS_ITEMS = [
  {
    id: 0,
    title: 'SaaS & Technology',
    description:
      'Let AI tools for customer service handle technical support by answering product-related inquiries and offering troubleshooting advice, freeing your team to focus on innovation and product development.',
    icon: (
      <svg
        className="md:min-w-15-vw md:min-h-15-vw min-w-15 min-h-15 md:max-w-15-vw md:max-h-15-vw max-w-15 max-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_16506_242610)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_16506_242610)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_16506_242610)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_16506_242610)"
          />
        </g>
        <path
          d="M20.5 30C20.1022 30 19.7206 30.158 19.4393 30.4393C19.158 30.7206 19 31.1022 19 31.5V37.5C19 37.8978 19.158 38.2794 19.4393 38.5607C19.7206 38.842 20.1022 39 20.5 39H41.5C41.8978 39 42.2794 38.842 42.5607 38.5607C42.842 38.2794 43 37.8978 43 37.5V31.5C43 31.1022 42.842 30.7206 42.5607 30.4393C42.2794 30.158 41.8978 30 41.5 30M20.5 30H41.5M20.5 30C20.1022 30 19.7206 29.842 19.4393 29.5607C19.158 29.2794 19 28.8978 19 28.5V22.5C19 22.1022 19.158 21.7206 19.4393 21.4393C19.7206 21.158 20.1022 21 20.5 21H41.5C41.8978 21 42.2794 21.158 42.5607 21.4393C42.842 21.7206 43 22.1022 43 22.5V28.5C43 28.8978 42.842 29.2794 42.5607 29.5607C42.2794 29.842 41.8978 30 41.5 30M38.5 34.5H38.515M34 34.5H34.015M38.5 25.5H38.515M34 25.5H34.015"
          stroke="#05050D"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_16506_242610"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_16506_242610"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_16506_242610"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_16506_242610"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_16506_242610"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_16506_242610"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(46 90) rotate(40.6568) scale(112.045 206.092)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 1,
    title: 'E-commerce',
    description:
      "Automate order-related questions such as 'Where is my order?' and 'How can I return an item?' — allowing your support team to focus on complex customer issues while customer service AI tools manage routine inquiries.",
    icon: (
      <svg
        className="md:min-w-15-vw md:min-h-15-vw min-w-15 min-h-15 md:max-w-15-vw md:max-h-15-vw max-w-15 max-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_17290_252778)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_17290_252778)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_17290_252778)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_17290_252778)"
          />
        </g>
        <path
          d="M19 20.375H21.188C21.6468 20.375 22.0468 20.6873 22.1581 21.1325L22.8125 23.75M22.8125 23.75L25.4356 34.2425C25.5469 34.6877 25.9469 35 26.4058 35H39.4692C39.9281 35 40.3281 34.6877 40.4394 34.2425L42.7519 24.9925C42.9097 24.3614 42.4323 23.75 41.7817 23.75H22.8125Z"
          stroke="#05050D"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3125 40.625C26.2445 40.625 27 39.8695 27 38.9375C27 38.0055 26.2445 37.25 25.3125 37.25C24.3805 37.25 23.625 38.0055 23.625 38.9375C23.625 39.8695 24.3805 40.625 25.3125 40.625Z"
          stroke="#05050D"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9375 40.625C40.8695 40.625 41.625 39.8695 41.625 38.9375C41.625 38.0055 40.8695 37.25 39.9375 37.25C39.0055 37.25 38.25 38.0055 38.25 38.9375C38.25 39.8695 39.0055 40.625 39.9375 40.625Z"
          stroke="#05050D"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_17290_252778"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17290_252778"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17290_252778"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_17290_252778"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_17290_252778"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_17290_252778"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(91 120) rotate(47.0701) scale(58.7282 108.023)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    title: 'Services',
    description:
      'Streamline service operations by automating appointment scheduling, FAQs, and other common requests with the help of AI for customer service, giving your team time to focus on high-value client interactions.',
    icon: (
      <svg
        className="md:min-w-15-vw md:min-h-15-vw min-w-15 min-h-15 md:max-w-15-vw md:max-h-15-vw max-w-15 max-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_17290_252787)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_17290_252787)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_17290_252787)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_17290_252787)"
          />
        </g>
        <path
          d="M18.5 28.75C18.5 21.8463 24.0963 16.25 31 16.25C37.9038 16.25 43.5 21.8463 43.5 28.75V35.1925C43.5 37.25 41.725 38.75 39.75 38.75H36V28.75H41C41 26.0978 39.9464 23.5543 38.0711 21.6789C36.1957 19.8036 33.6522 18.75 31 18.75C28.3478 18.75 25.8043 19.8036 23.9289 21.6789C22.0536 23.5543 21 26.0978 21 28.75H26V38.75H23.5788C23.7173 39.2867 24.0304 39.7622 24.4687 40.1016C24.907 40.441 25.4457 40.6251 26 40.625H27.955C28.35 40.0588 29.0063 39.6875 29.75 39.6875H32.25C32.8302 39.6875 33.3866 39.918 33.7968 40.3282C34.207 40.7384 34.4375 41.2948 34.4375 41.875C34.4375 42.4552 34.207 43.0116 33.7968 43.4218C33.3866 43.832 32.8302 44.0625 32.25 44.0625H29.75C29.0063 44.0625 28.35 43.6913 27.955 43.125H26C24.7474 43.1252 23.5403 42.6551 22.6177 41.8079C21.695 40.9607 21.124 39.7981 21.0175 38.55C19.5925 38.0788 18.5 36.8075 18.5 35.1938V28.75ZM23.5 36.25V31.25H21V35.1925C21 35.6838 21.4625 36.25 22.25 36.25H23.5ZM41 31.25H38.5V36.25H39.75C40.5375 36.25 41 35.6838 41 35.1925V31.25Z"
          fill="black"
        />
        <defs>
          <filter
            id="filter0_d_17290_252787"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17290_252787"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17290_252787"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_17290_252787"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_17290_252787"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_17290_252787"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(132 83) rotate(91.7899) scale(64.0312 117.777)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 3,
    title: 'Finance & Banking',
    description:
      'Provide secure, compliant AI-powered customer service for account inquiries, payment terms transaction issues, etc., enhancing customer engagement with faster response times.',
    icon: (
      <svg
        className="md:min-w-15-vw md:min-h-15-vw min-w-15 min-h-15 md:max-w-15-vw md:max-h-15-vw max-w-15 max-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_17297_252792)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_17297_252792)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_17297_252792)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_17297_252792)"
          />
        </g>
        <path
          d="M42.9364 28.4374C43.902 28.4374 44.3051 27.1968 43.5208 26.6249L31.5864 17.9437C31.416 17.8188 31.2102 17.7515 30.9989 17.7515C30.7876 17.7515 30.5818 17.8188 30.4114 17.9437L18.477 26.6249C17.6926 27.1937 18.0958 28.4374 19.0645 28.4374H20.9989V40.1249H18.7489C18.6114 40.1249 18.4989 40.2374 18.4989 40.3749V41.9999C18.4989 42.1374 18.6114 42.2499 18.7489 42.2499H43.2489C43.3864 42.2499 43.4989 42.1374 43.4989 41.9999V40.3749C43.4989 40.2374 43.3864 40.1249 43.2489 40.1249H40.9989V28.4374H42.9364ZM30.9989 20.1468L39.4708 26.3093H22.527L30.9989 20.1468ZM23.2489 28.4374H26.9051V40.1249H23.2489V28.4374ZM29.1551 28.4374H32.8114V40.1249H29.1551V28.4374ZM38.7489 40.1249H35.0614V28.4374H38.7489V40.1249Z"
          fill="#05050D"
          stroke="#05050D"
          strokeWidth="0.3"
        />
        <defs>
          <filter
            id="filter0_d_17297_252792"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17297_252792"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17297_252792"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_17297_252792"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80 97) rotate(70.5234) scale(86.977)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_17297_252792"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(155 90) rotate(142.164) scale(130.419)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_17297_252792"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(56 54) rotate(51.4908) scale(118.849 218.607)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 4,
    title: 'BPO Companies',
    description:
      'Leverage generative AI tools for customer service to optimize large-scale operations, reduce ticket resolution times, and handle high-volume customer interactions while maintaining consistent service quality.',
    icon: (
      <svg
        className="md:min-w-15-vw md:min-h-15-vw min-w-15 min-h-15 md:max-w-15-vw md:max-h-15-vw max-w-15 max-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_17297_252801)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_17297_252801)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_17297_252801)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_17297_252801)"
          />
        </g>
        <path
          d="M35.5026 26.9166V19.3333H20.3359V34.4999H27.9193"
          stroke="#05050D"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.2552 34.4998H27.9219M37.2552 34.4998L34.3385 31.5832M37.2552 34.4998L34.3385 37.4165M43.0885 42.0832V26.9165H27.9219V42.0832H43.0885Z"
          stroke="#05050D"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_17297_252801"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17297_252801"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17297_252801"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_17297_252801"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(91 60) rotate(90) scale(88)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_17297_252801"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(39 120) rotate(128.403) scale(104.637)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_17297_252801"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(126 90) rotate(139.399) scale(46.0977 84.7909)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
];
