import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { Card } from './Card';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { BenefitCarousel } from './BenefitCarousel';
import { BENEFIT_ITEMS } from './carousel-items';
import { MobileSlider } from '@/components/MobileSlider/MobileSlider';

export const WhoBenefitsFromCoSupportAI = () => {
  const isMdUp = useMediaQuery('(min-width: 768px)');

  return (
    <div className="relative bg-light">
      <Container className="md:pt-12.5-vw md:pb-24.5-vw max-md:pb-10 relative">
        <section className="flex flex-col md:gap-5-vw gap-4">
          <div>
            <Typography $type="Heading2Big" className="font-formula" $weight="700">
              AI Customer Service Use Cases
            </Typography>
          </div>
          {isMdUp ? (
            <BenefitCarousel />
          ) : (
            <MobileSlider
              itemClassName="basis-[95%] pr-2"
              className="!mb-0"
              carouselWrapperClassName="pb-3 w-[calc(100%+2rem)] -ml-4"
              slides={BENEFIT_ITEMS.map((cardData) => {
                return {
                  id: cardData.id + 1,
                  content: <Card item={cardData} />,
                };
              })}
            />
          )}
        </section>
      </Container>
    </div>
  );
};
