import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { MediaRecognition } from '@/components/MediaRecognition/MediaRecognition';
import { useIntersection } from '@/hooks/useIntersection';
import { ReactVideoComponent } from '@/components/ReactVideoComponent/ReactVideoComponent';
import { MAIN_PAGE_VIDEO_LINk } from '@/constants/links';

export const HowCoSupportAIWorks = () => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isVisible = useIntersection(playerRef, '-50%');

  useEffect(() => {
    setIsPlaying(isVisible);
  }, [isVisible]);
  return (
    <div className="bg-white relative md:pt-12.5-vw pt-2">
      <div className="w-full">
        <MediaRecognition isDark={false} className="!bg-white !m-0" />
      </div>
      <Container className="md:pt-16-vw pt-10 flex justify-center">
        <section className="flex flex-col justify-center items-center md:gap-10-vw gap-4 w-full">
          <div className="md:max-w-[59.458vw]">
            <Typography
              as="h2"
              $type="Heading1"
              $weight="700"
              className="font-formula md:text-center text-start"
            >
              How CoSupport AI works
            </Typography>
            <Typography
              $type="Heading4"
              $weight="400"
              className="font-jamjuree md:text-center text-start"
            >
              A million sales mean a million customer inquiries, and they demand fast and efficient
              handling. With CoSupport AI’s advanced conversational AI for customer service, you can
              streamline support, ensure quick resolutions, and keep your customers satisfied.
            </Typography>
          </div>
          <ReactVideoComponent
            className="!p-0"
            ref={playerRef}
            playing={isPlaying}
            onPause={() => {
              setIsPlaying(isVisible);
            }}
            onClick={(player: any) => {
              player.seekTo(1.6);
            }}
            videoUrl={MAIN_PAGE_VIDEO_LINk}
          />
        </section>
      </Container>
    </div>
  );
};
