import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from '@/components/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { clsx } from 'clsx';

type ContentProps = {
  className?: string;
  autoScroll?: boolean;
  carouselWrapperClassName?: string;
  carouselClassName?: string;
  itemClassName?: string;
  dotColor?: 'black' | 'white';
  slides: {
    id: number;
    content: React.ReactNode;
  }[];
};

export const MobileSlider = ({
  slides = [],
  className,
  dotColor = 'black',
  autoScroll = false,
  carouselWrapperClassName = 'py-7',
  carouselClassName,
  itemClassName,
}: ContentProps) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  const plugin = useRef(Autoplay({ delay: 6000 }));

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div
      className={clsx(
        'mb-14 h-full w-full min-h-full flex flex-col items-center transition duration-500 justify-between relative',
        className
      )}
    >
      <Container padding="px-0">
        <div className={clsx(carouselWrapperClassName)}>
          <Carousel
            plugins={autoScroll ? [plugin.current] : []}
            setApi={setApi}
            className={clsx(carouselClassName, 'w-full')}
          >
            <CarouselContent className="m-4">
              {slides.map((slide, index, array) => (
                <CarouselItem
                  key={`${slide.id}-${index}`}
                  className={clsx(index !== array.length - 1 && 'mr-2', 'pl-0', itemClassName)}
                >
                  {slide.content}
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <div className="flex gap-4 justify-center px-4 py-2.5 text-center text-sm text-muted-foreground absolute -bottom-4">
            {slides.map((slide, index) => (
              <div
                onClick={() => api?.scrollTo(index)}
                key={`${slide.id}`}
                className={clsx(
                  'h-[9px] w-[9px] rounded-full cursor-pointer',
                  slide.id !== current && 'opacity-40',
                  dotColor === 'black' ? 'bg-ebony' : 'bg-white'
                )}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
