import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { clsx } from 'clsx';

const TAGS_ITEMS = [
  'Custom AI models',
  'Unlimited user access',
  'Non-hallucinating AI',
  'Transparent pricing',
  'Enterprise-grade security',
];

type Props = {
  color?: string;
  className?: string;
};

const CheckIcon = ({ color }: Props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.41062 10.3499L11.3456 5.41488L10.3656 4.43488L6.41062 8.38988L4.41563 6.39488L3.43563 7.37488L6.41062 10.3499ZM7.39062 14.1299C6.42229 14.1299 5.51229 13.946 4.66062 13.5783C3.80896 13.2105 3.06813 12.7119 2.43813 12.0824C1.80813 11.4528 1.30949 10.712 0.942226 9.85988C0.574959 9.00775 0.391093 8.09775 0.390626 7.12988C0.390159 6.16201 0.574026 5.25202 0.942226 4.39988C1.31043 3.54775 1.80906 2.80692 2.43813 2.17738C3.06719 1.54785 3.80803 1.04922 4.66062 0.681483C5.51323 0.313749 6.42322 0.129883 7.39062 0.129883C8.35802 0.129883 9.26802 0.313749 10.1206 0.681483C10.9732 1.04922 11.7141 1.54785 12.3431 2.17738C12.9722 2.80692 13.4711 3.54775 13.8397 4.39988C14.2084 5.25202 14.392 6.16201 14.3906 7.12988C14.3892 8.09775 14.2054 9.00775 13.839 9.85988C13.4727 10.712 12.9741 11.4528 12.3431 12.0824C11.7122 12.7119 10.9714 13.2108 10.1206 13.579C9.26989 13.9472 8.35989 14.1308 7.39062 14.1299Z"
        fill={color}
      />
    </svg>
  );
};
export const Tags = ({ color = COLORS.WHITE, className }: Props) => {
  return (
    <div
      className={clsx(
        className,
        'w-full flex justify-center flex-wrap md:gap-4-vw gap-3 md:max-w-[43vw]'
      )}
    >
      {TAGS_ITEMS.map((tag) => {
        return (
          <div key={tag} className="flex items-center md:gap-2-vw gap-1.5">
            <CheckIcon color={color} />
            <Typography $type="PRegular" $weight="400" $color={color} className="font-jamjuree">
              {tag}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
