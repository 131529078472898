import React from 'react';
import { Articles } from '@/pages/articles';
import { Typography } from '@/components/Typography/Typography';
import { Container } from '@/components/Containers/Container';
import Article from '@/components/Article/Article';

type ArticlesSectionProps = {
  articles: Articles[];
};

export const LatestArticlesSection = ({ articles }: ArticlesSectionProps) => {
  return (
    <section className="relative bg-white md:py-18-vw py-10 ">
      <Container className="w-full">
        <div className="flex flex-col md:gap-5-vw gap-4">
          <div className="flex flex-col">
            <Typography
              as="h3"
              $type="Heading1"
              $weight="700"
              className="md:text-start text-left font-formula md:max-w-[59.4vw]"
            >
              AI Customer Service: Latest Trends and Insights
            </Typography>
          </div>
          <div className="flex max-md:flex-col md:gap-6-vw gap-4">
            {articles.map((articleData) => (
              <Article
                authorHidden
                key={articleData.id}
                // @ts-ignore
                article={articleData}
                className="md:basis-1/3 max-md:!w-full !mt-0 [&_.content-block_p]:!text-base [&_.image-block_img]:!rounded-md-10"
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
