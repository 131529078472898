import React from 'react';
import { TextBlock } from './TextBlock/TextBlock';
import { Container } from '@/components/Containers/Container';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { PATH_DEMO, PRODUCT_DEMO } from '@/constants/spa-routes';
import { Typography } from '@/components/Typography/Typography';
import { Tags } from '@/components/PagesSections/MainPageSections/FirstSection/Content/Tags';
import { BlackButton } from '@/components/ButtonsCollection/BlackButton/BlackButton';
import { COLORS } from '@/constants/colors';
import { SmallArrowIcon } from '@/assets/icons/smallArrowIcon';
import { clsx } from 'clsx';

export const Content = () => {
  return (
    <Container className="relative overflow-hidden">
      <div
        className={clsx(
          'flex flex-col justify-center items-center md:pt-24-vw pt-8 md:pb-15-vw z-50'
        )}
      >
        <TextBlock className="w-full grow md:mb-2.5-vw mb-2" />
        <Tags color={COLORS.EBONY} />
        <div className="flex flex-col md:gap-10-vw gap-6 items-center z-50 md:mt-10-vw mt-8">
          <div className="flex max-md:w-full flex-wrap md:gap-5-vw gap-4 justify-center">
            <GreenButton
              linkBtnClassName="max-md:w-full"
              url={PRODUCT_DEMO.ROOT}
              className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
            >
              <Typography $type="Heading4" $weight="700">
                See in Action
              </Typography>
            </GreenButton>
            <BlackButton
              url={PATH_DEMO.ROOT}
              className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
              linkBtnClassName="max-md:w-full !px-11-vw"
            >
              <Typography $color={COLORS.WHITE} $type="Heading4" $weight="700">
                Request demo
              </Typography>
              <SmallArrowIcon />
            </BlackButton>
          </div>
        </div>
      </div>
    </Container>
  );
};
