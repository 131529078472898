import React, { ReactNode } from 'react';
import { Typography } from '@/components/Typography/Typography';

type CardProps = {
  item: {
    id: number;
    title: string;
    description: ReactNode | string;
  };
};
export const DescriptionCard = ({ item }: CardProps) => {
  return (
    <div className="h-full flex flex-col md:gap-5-vw gap-4">
      <div className="flex flex-col gap-1">
        <Typography $type="PMedium" className="font-jamjuree italic" $weight="600">
          {item.title}
        </Typography>
        <Typography $type="PMedium" className="font-jamjuree opacity-70" $weight="400">
          {item.description}
        </Typography>
      </div>
    </div>
  );
};
