import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { BackgroundAnimation } from './BackgroundAnimation';
import { SOFTORINO_WEBSITE_LINK } from '@/constants/links';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { PATH_CASE_STUDIES } from '@/constants/spa-routes';

const Icon1 = () => {
  return (
    <svg
      className="md:min-w-12.5-vw md:min-h-12.5-vw min-w-12.5 min-h-12.5 md:max-w-12.5-vw md:max-h-12.5-vw max-w-12.5 max-h-12.5"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17011_239140)">
        <path
          d="M33.1334 17.7083L34.158 18.8039L33.5872 19.3377L32.8227 19.1758L33.1334 17.7083ZM13.4558 13.5417L12.5983 12.3109L13.1311 11.9397L13.7665 12.0742L13.4558 13.5417ZM0.857487 24.1474L-0.373249 25.0049L-2.08823 22.5434L-0.857487 21.6859L0.857487 24.1474ZM52.0246 2.09558L34.158 18.8039L32.1089 16.6128L49.9754 -0.0955769L52.0246 2.09558ZM32.8227 19.1758L13.145 15.0091L13.7665 12.0742L33.4442 16.2409L32.8227 19.1758ZM14.3133 14.7724L0.857487 24.1474L-0.857487 21.6859L12.5983 12.3109L14.3133 14.7724Z"
          fill="white"
        />
        <path
          d="M0 50.0001V22.9167L12.9808 14.5834L32.6923 18.2292L50 1.04175V50.0001H0Z"
          fill="url(#paint0_linear_17011_239140)"
          fillOpacity="0.6"
        />
        <line y1="49.5" x2="50" y2="49.5" stroke="white" strokeOpacity="0.1" />
        <line x1="8.5" y1="17" x2="8.5" y2="49" stroke="white" strokeOpacity="0.1" />
        <line x1="19.5" y1="15" x2="19.5" y2="49" stroke="white" strokeOpacity="0.1" />
        <line x1="30.5" y1="17" x2="30.5" y2="49" stroke="white" strokeOpacity="0.1" />
        <line x1="41.5" y1="10" x2="41.5" y2="49" stroke="white" strokeOpacity="0.1" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17011_239140"
          x1="25.4808"
          y1="1.04175"
          x2="25.4808"
          y2="44.7917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_17011_239140">
          <rect width="50" height="50.0002" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Icon2 = () => {
  return (
    <svg
      className="md:min-w-12.5-vw md:min-h-12.5-vw min-w-12.5 min-h-12.5 md:max-w-12.5-vw md:max-h-12.5-vw max-w-12.5 max-h-12.5"
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17011_239152)">
        <line x1="0.5" y1="49.5" x2="50.5" y2="49.5" stroke="white" strokeOpacity="0.1" />
        <path
          d="M50.5 50.0001V28.4998L31 25.4998L17.5 9.99976L0.5 1.04175V50.0001H50.5Z"
          fill="url(#paint0_linear_17011_239152)"
          fillOpacity="0.6"
        />
        <path
          d="M17.5 10.5L18.6132 9.49457L18.4293 9.29097L18.1852 9.16565L17.5 10.5ZM31.5 26L30.3868 27.0054L30.7528 27.4106L31.2936 27.4857L31.5 26ZM49.2936 29.9857L50.7794 30.1921L51.1921 27.2206L49.7064 27.0143L49.2936 29.9857ZM-1.68521 2.33435L16.8148 11.8344L18.1852 9.16565L-0.314793 -0.334351L-1.68521 2.33435ZM16.3868 11.5054L30.3868 27.0054L32.6132 24.9946L18.6132 9.49457L16.3868 11.5054ZM31.2936 27.4857L49.2936 29.9857L49.7064 27.0143L31.7064 24.5143L31.2936 27.4857Z"
          fill="white"
        />
        <line
          y1="-0.5"
          x2="22"
          y2="-0.5"
          transform="matrix(-6.4617e-08 1 1 2.95696e-08 42.5 27)"
          stroke="white"
          strokeOpacity="0.1"
        />
        <line
          y1="-0.5"
          x2="23"
          y2="-0.5"
          transform="matrix(-6.89244e-08 1 1 2.77213e-08 31.5 26)"
          stroke="white"
          strokeOpacity="0.1"
        />
        <line x1="21" y1="14" x2="21" y2="49" stroke="white" strokeOpacity="0.1" />
        <line x1="10" y1="5" x2="10" y2="49" stroke="white" strokeOpacity="0.1" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17011_239152"
          x1="25.0192"
          y1="1.04175"
          x2="25.0192"
          y2="44.7917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_17011_239152">
          <rect width="50" height="50.0002" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SuccessStoriesSection = () => {
  return (
    <div className="bg-ebony relative overflow-hidden">
      <BackgroundAnimation />
      <Container className="max-md:backdrop-blur-[150px] md:pt-16-vw pt-10 md:pb-16-vw pb-10 flex gap-10-vw justify-center">
        <div className="md:max-w-[59.444vw] flex flex-col md:gap-10-vw gap-8">
          <div className="flex flex-col items-center md:gap-5-vw gap-5 z-50">
            <Typography
              as="h2"
              $weight="700"
              $type="Heading1"
              className="font-formula text-center"
              $color={COLORS.WHITE}
            >
              Success Stories
            </Typography>
            <Typography
              $color={COLORS.WHITE}
              $weight="400"
              $type="Heading4"
              className="font-jamjuree text-center"
            >
              <a
                className="underline !text-white hover:!text-blue-400 transition italic"
                target="_blank"
                href={SOFTORINO_WEBSITE_LINK}
                rel="noreferrer"
              >
                Softorino
              </a>
              <span className="opacity-70">
                , a software development company, selected CoSupport AI over six competitors to
                enhance their customer service, marketing, and HR teams, which resulted in:
              </span>
            </Typography>
          </div>
          <div className="flex flex-col md:gap-10-vw gap-6 items-center z-50">
            <div className="w-full bg-white flex items-center justify-center bg-opacity-5 md:rounded-[40px] rounded-[15px] border border-[#FFFFFF33] md:p-5-vw p-2">
              <div className="h-full flex items-center justify-center w-full md:rounded-lg-20 rounded-md-10 bg-ebony bg-opacity-50 relative">
                <div className="flex flex-col items-center md:py-18-vw py-11">
                  <Typography
                    $type="Heading1Medium"
                    $fontSizeSm="7.639vw"
                    $weight="700"
                    className="font-formula"
                    $color={COLORS.GREEN}
                  >
                    ~$ 84,000
                  </Typography>
                  <Typography
                    $type="Heading3"
                    className="italic"
                    $weight="500"
                    $color={COLORS.WHITE}
                  >
                    reduction in annual operating costs
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center md:gap-15-vw gap-8">
              <div className="flex items-center md:gap-15-vw gap-6 md:w-11/12">
                <div className="flex items-center max-md:flex-col md:gap-6-vw gap-2">
                  <div className="flex items-center md:gap-6-vw gap-2">
                    <Icon1 />
                    <Typography
                      $type="Heading1Medium"
                      className="font-formula"
                      $weight="700"
                      $color={COLORS.WHITE}
                    >
                      30%
                    </Typography>
                  </div>
                  <Typography
                    $type="Heading4"
                    className="font-jamjuree opacity-70"
                    $weight="400"
                    $color={COLORS.WHITE}
                  >
                    more resolved tickets
                  </Typography>
                </div>
                <div className="flex items-center max-md:flex-col md:gap-6-vw gap-2">
                  <div className="flex items-center md:gap-6-vw gap-2">
                    <Icon2 />
                    <Typography
                      $type="Heading1Medium"
                      className="font-formula"
                      $weight="700"
                      $color={COLORS.WHITE}
                    >
                      53%
                    </Typography>
                  </div>
                  <Typography
                    $type="Heading4"
                    className="font-jamjuree opacity-70"
                    $weight="400"
                    $color={COLORS.WHITE}
                  >
                    faster response time
                  </Typography>
                </div>
              </div>
              <div className="flex max-md:w-full flex-wrap justify-center">
                <GreenButton
                  linkBtnClassName="max-md:w-full"
                  url={PATH_CASE_STUDIES.SOFTORINO}
                  className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
                >
                  <Typography $type="Heading4" $weight="700">
                    Read the full story
                  </Typography>
                </GreenButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
