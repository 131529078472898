export const BENEFIT_ITEMS = [
  {
    id: 0,
    title: 'Executives and Founders:',
    description:
      'Boost customer satisfaction, reduce operational costs, and gain actionable insights for strategic decision-making.',
    icon: (
      <svg
        className="md:min-w-[62px] md:min-h-[62px] min-w-15 min-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_19961_16621)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_19961_16621)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_19961_16621)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_19961_16621)"
          />
        </g>
        <path
          d="M31.0002 22C32.4668 22 33.6668 23.2 33.6668 24.6667C33.6668 26.1334 32.4668 27.3334 31.0002 27.3334C29.5335 27.3334 28.3335 26.1334 28.3335 24.6667C28.3335 23.2 29.5335 22 31.0002 22ZM31.0002 35.3334C34.6002 35.3334 38.7335 37.0534 39.0002 38H23.0002C23.3068 37.04 27.4135 35.3334 31.0002 35.3334ZM31.0002 19.3334C28.0535 19.3334 25.6668 21.72 25.6668 24.6667C25.6668 27.6134 28.0535 30 31.0002 30C33.9468 30 36.3335 27.6134 36.3335 24.6667C36.3335 21.72 33.9468 19.3334 31.0002 19.3334ZM31.0002 32.6667C27.4402 32.6667 20.3335 34.4534 20.3335 38V40.6667H41.6668V38C41.6668 34.4534 34.5602 32.6667 31.0002 32.6667Z"
          fill="#05050D"
        />
        <defs>
          <filter
            id="filter0_d_19961_16621"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_19961_16621"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_19961_16621"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_19961_16621"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_19961_16621"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_19961_16621"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(46 90) rotate(40.6568) scale(112.045 206.092)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 1,
    title: 'Customer Service Managers:',
    description:
      'Automate routine inquiries, reduce ticket backlog and improve response accuracy while focusing on high-priority tasks.',
    icon: (
      <svg
        className="md:min-w-[62px] md:min-h-[62px] min-w-15 min-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_19961_16638)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_19961_16638)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_19961_16638)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_19961_16638)"
          />
        </g>
        <path
          d="M18.9165 28.7918C18.9165 22.1182 24.3262 16.7085 30.9998 16.7085C37.6735 16.7085 43.0832 22.1182 43.0832 28.7918V35.0196C43.0832 37.0085 41.3673 38.4585 39.4582 38.4585H35.8332V28.7918H40.6665C40.6665 26.2281 39.6481 23.7693 37.8352 21.9565C36.0224 20.1436 33.5636 19.1252 30.9998 19.1252C28.4361 19.1252 25.9773 20.1436 24.1645 21.9565C22.3516 23.7693 21.3332 26.2281 21.3332 28.7918H26.1665V38.4585H23.826C23.9599 38.9774 24.2625 39.437 24.6862 39.7651C25.1099 40.0932 25.6306 40.2711 26.1665 40.271H28.0563C28.4382 39.7236 29.0725 39.3647 29.7915 39.3647H32.2082C32.769 39.3647 33.3068 39.5875 33.7034 39.9841C34.1 40.3807 34.3228 40.9185 34.3228 41.4793C34.3228 42.0402 34.1 42.578 33.7034 42.9746C33.3068 43.3711 32.769 43.5939 32.2082 43.5939H29.7915C29.0725 43.5939 28.4382 43.235 28.0563 42.6877H26.1665C24.9556 42.6878 23.7888 42.2335 22.8969 41.4145C22.005 40.5955 21.453 39.4716 21.3501 38.2652C19.9726 37.8096 18.9165 36.5807 18.9165 35.0208V28.7918ZM23.7498 36.0418V31.2085H21.3332V35.0196C21.3332 35.4945 21.7803 36.0418 22.5415 36.0418H23.7498ZM40.6665 31.2085H38.2498V36.0418H39.4582C40.2194 36.0418 40.6665 35.4945 40.6665 35.0196V31.2085Z"
          fill="#05050D"
        />
        <defs>
          <filter
            id="filter0_d_19961_16638"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_19961_16638"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_19961_16638"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_19961_16638"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_19961_16638"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_19961_16638"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(91 120) rotate(47.0701) scale(58.7282 108.023)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    title: 'Product Managers:',
    description:
      'Integrate AI-driven support seamlessly with your product, ensuring scalability and a better user experience.',
    icon: (
      <svg
        className="md:min-w-[62px] md:min-h-[62px] min-w-15 min-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_19959_16096)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_19959_16096)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_19959_16096)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_19959_16096)"
          />
        </g>
        <g clipPath="url(#clip0_19959_16096)">
          <path
            d="M35.6667 15.3334H25.0001C24.116 15.3334 23.2682 15.6846 22.6431 16.3097C22.0179 16.9348 21.6667 17.7827 21.6667 18.6667V41.3334C21.6667 42.2174 22.0179 43.0653 22.6431 43.6904C23.2682 44.3155 24.116 44.6667 25.0001 44.6667H35.6667C36.5508 44.6667 37.3987 44.3155 38.0238 43.6904C38.6489 43.0653 39.0001 42.2174 39.0001 41.3334V18.6667C39.0001 17.7827 38.6489 16.9348 38.0238 16.3097C37.3987 15.6846 36.5508 15.3334 35.6667 15.3334ZM30.3334 43.3334C29.2267 43.3334 28.3334 42.44 28.3334 41.3334C28.3334 40.2267 29.2267 39.3334 30.3334 39.3334C31.4401 39.3334 32.3334 40.2267 32.3334 41.3334C32.3334 42.44 31.4401 43.3334 30.3334 43.3334ZM36.3334 38H24.3334V19.3334H36.3334V38Z"
            fill="#05050D"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_19959_16096"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_19959_16096"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_19959_16096"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_19959_16096"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(97 71) rotate(83.6598) scale(108.665)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_19959_16096"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(162 101) rotate(147.901) scale(129.85)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_19959_16096"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(132 83) rotate(91.7899) scale(64.0312 117.777)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <clipPath id="clip0_19959_16096">
            <rect width="30" height="30" fill="white" transform="translate(16 15)" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 3,
    title: 'Sales Managers:',
    description:
      'Leverage AI to automate pre-sales inquiries, identify upsell opportunities, and shorten sales cycles.',
    icon: (
      <svg
        className="md:min-w-[62px] md:min-h-[62px] min-w-15 min-h-15"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_19959_16110)">
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="white"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint0_radial_19959_16110)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint1_radial_19959_16110)"
          />
          <rect
            x="61"
            y="60"
            width="60"
            height="60"
            rx="5"
            transform="rotate(-180 61 60)"
            fill="url(#paint2_radial_19959_16110)"
          />
        </g>
        <path
          d="M24.3333 34H26.9999C26.9999 35.44 28.8266 36.6667 30.9999 36.6667C33.1733 36.6667 34.9999 35.44 34.9999 34C34.9999 32.5333 33.6133 32 30.6799 31.2933C27.8533 30.5867 24.3333 29.7067 24.3333 26C24.3333 23.6133 26.2933 21.5867 28.9999 20.9067V18H32.9999V20.9067C35.7066 21.5867 37.6666 23.6133 37.6666 26H34.9999C34.9999 24.56 33.1733 23.3333 30.9999 23.3333C28.8266 23.3333 26.9999 24.56 26.9999 26C26.9999 27.4667 28.3866 28 31.3199 28.7067C34.1466 29.4133 37.6666 30.2933 37.6666 34C37.6666 36.3867 35.7066 38.4133 32.9999 39.0933V42H28.9999V39.0933C26.2933 38.4133 24.3333 36.3867 24.3333 34Z"
          fill="#05050D"
        />
        <defs>
          <filter
            id="filter0_d_19959_16110"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_19959_16110"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_19959_16110"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_19959_16110"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80 97) rotate(70.5234) scale(86.977)"
          >
            <stop stopColor="#BBFFFB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_19959_16110"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(155 90) rotate(142.164) scale(130.419)"
          >
            <stop stopColor="#F1FFBB" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_19959_16110"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(56 54) rotate(51.4908) scale(118.849 218.607)"
          >
            <stop stopColor="#ECE5FF" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    ),
  },
];
