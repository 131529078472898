import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { clsx } from 'clsx';

type TextBlockProps = {
  className?: string;
};

export const TextBlock = ({ className }: TextBlockProps) => {
  return (
    <div
      className={clsx(
        'flex flex-col md:gap-10-vw gap-6 justify-center md:max-w-[60.417vw]',
        className
      )}
    >
      <div className="text-container flex flex-col md:gap-2.5-vw gap-2">
        <div className="flex justify-center">
          <Typography
            $breakpoint="1024"
            as="h1"
            $type="Heading1Large"
            $weight="700"
            $fontSizeSm="4.167vw"
            $color={COLORS.EBONY}
            className="md:text-center text-left max-md:max-w-[85%] font-formula"
          >
            All-in-one AI for Customer Service
          </Typography>
        </div>
      </div>
    </div>
  );
};
