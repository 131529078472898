import { PATENT_LINK } from '@/constants/links';
import React from 'react';
import Link from 'next/link';
import { PATH_ARTICLES, PATH_DEMO } from '@/constants/spa-routes';

export const FAQMainPageList = [
  {
    question: 'What is AI Customer Service?',
    answer: (
      <div>
        <p>
          A generative AI solution or AI for customer service is a suite of technologies that, when
          applied to the customer service domain, enhance performance and cost-efficiency. The term
          “generative AI” is related to Artificial Intelligence’s ability to produce original
          content in the form of a text, image, or voice message.
        </p>{' '}
        <br />
        <p>
          The primary task of AI tools for customer service built on generative AI technologies is
          routine task automation. Below are a few use cases on how AI for customer service handles
          this task:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>Automates the resolution process for a portion of the support workflow;</p>
          </li>
          <li>
            <p>Triages support requests;</p>
          </li>
          <li>
            <p>Provides customer service agents with reply suggestions;</p>
          </li>
          <li>
            <p>Analyzes customer correspondence.</p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How to use AI in customer service for a business’s benefit?',
    answer: (
      <div>
        <p>
          AI-powered customer service exists in various forms; however, the most widespread AI
          solution is AI-powered chatbots and assistants that communicate with customers in a
          human-like manner.
        </p>
        <br />
        <p>
          AI customer service software like chatbots and assistants integrates with different
          channels of communication, such as email, social media, live chats, interactive voice
          response (IVR) systems, and self-service portals.
        </p>
        <p>
          The integration of generative AI customer service solutions results in response time
          reduction, rate of resolved tickets increases, reduction in operating costs, and other
          enhancements to customer service-related metrics. The final goal of an AI integration to
          customer service solution is the enhanced customer experience and a higher level of
          customer satisfaction.
        </p>
      </div>
    ),
  },
  {
    question: 'CoSupport AI: how can AI improve customer service?',
    answer: (
      <div>
        <p>
          CoSupport’s AI tools for customer service entail the following benefits for businesses:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              24/7 support that excludes human errors, lack of motivation, and distractors impact;
            </p>
          </li>
          <li>
            <p>
              considerable savings of time and effort, along with the easy scaling and delegation of
              routine tasks to AI assistants with minimal resource investment;
            </p>
          </li>
          <li>
            <p>
              substantial reduction in costs in terms of hiring staff and maintaining your
              operational processes. 
            </p>
          </li>
        </ul>
        <p>
          <Link
            href={PATH_ARTICLES.AI_WINS}
            className="underline-offset-1 underline text-ebony opacity-70"
            prefetch={false}
          >
            <b>
              <i>Klarna’s case</i>
            </b>
          </Link>{' '}
          is a good example of the business benefits achievable through AI customer service
          solutions. Klarna achieved a reduction of response time from 11 minutes to 2 and a
          decrease in repeated queries by 25%. The company announced that it expected a profit
          increase of $40 million due to customer service AI tools.
        </p>
      </div>
    ),
  },
  {
    question:
      'What sets CoSupport AI tools for customer service apart from different AI for customer service solutions?',
    answer: (
      <div>
        <p>
          CoSupport AI tools for customer service are based on what the team calls native AI. It’s a
          suite of distinct approaches that allow for building deeply custom products. 
        </p>
        <p>
          Customization is treated not only in general terms, meaning that products are tailored to
          the needs of a particular company. Customization also implies that the opportunity to
          adjust a solution to a particular business is wider compared to the solutions that are
          based on ready-to-use products, such as well-known LLMs. 
        </p>
        <p>
          CoSupport AI builds customer service AI tools adjusting key generative AI model parameters
          and influencing the outcome directly. Here are four examples:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport AI uses the{' '}
              <a
                href={PATENT_LINK}
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-1 cursor-pointer !text-ebony opacity-70"
              >
                <b>
                  <i>U.S. patented</i>
                </b>
              </a>{' '}
              response acceleration architecture that allows one solution, CoSupport Agent, to
              provide{' '}
              <Link
                href={PATH_ARTICLES.PATENT}
                className="underline-offset-1 underline text-ebony opacity-70"
                prefetch={false}
              >
                <b>
                  <i>three times faster responses</i>
                </b>
              </Link>{' '}
              compared to GPT;
            </p>
          </li>
          <li>
            <p>
              the training data involved in a particular project never overlaps with the data
              related to other projects and provides close to zero risk of data leakage;
            </p>
          </li>
          <li>
            <p>
              Our Intelligent Embedding approach, based on the embedding that a dedicated model
              performs, lays the foundation for high response precision;
            </p>
          </li>
          <li>
            <p>
              databases for back-end operations are organized in a way that provides close to zero
              risk of jailbreaking - the intrusion to the models’ “brains” for stealing sensitive
              data.
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question:
      'What do I need to implement customer service AI tools for business with CoSupport AI as a partner?',
    answer: (
      <div>
        <p>
          No special setup is needed to implement AI customer service solutions for business, but a
          well-structured knowledge base is key. CoSupport AI customizes its solutions, CoSupport
          Agent and CoSupport Customer, using project-related databases while ensuring data security
          through dedicated servers and the CoSupport AI Data Grid.
        </p>
      </div>
    ),
  },
  {
    question: 'How can AI help customer service to automate workflow?',
    answer: (
      <div>
        <p>
          CoSupport’s AI-powered customer service solutions automate 80% to 100% of tasks, depending
          on the solution:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Agent automates up to 80% of responses, assisting agents with draft replies,
              reducing response time, and improving resolution rates while leaving the final
              decision to the agent.
            </p>
          </li>
          <li>
            <p>
              CoSupport Customer offers up to 100% automation, scaling up customer service
              efficiently.
            </p>
          </li>
          <li>
            <p>CoSupport BI focuses on delivering business insights, not response automation.</p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How can AI help customer service? Which customer service AI tools fit your needs?',
    answer: (
      <div>
        <p>
          A few of our clients start with CoSupport Agent because they want to test-drive the
          system. When they see how accurate our responses are, they invariably convert to CoSupport
          Customer. Over 76% of our customers go on to sign up for CoSupport BI.
        </p>
        <br />
        <br />
        <p>Set up a consultation for a demo of each solution and to discuss your needs.</p>
      </div>
    ),
  },
  {
    question: 'Which customer service AI tools can I integrate CoSupport AI solutions with?',
    answer: (
      <div>
        <p>
          CoSupport AI for customer service solutions integrates with all the popular customer
          service AI platforms and tools for customer service agents and team leads. Here is the
          list of possible integrations:
        </p>
        <ul className="list-disc pl-5">
          <li>
            <p>
              CoSupport Customer integrates with various communication channels like email, social
              media, or website and application chats;
            </p>
          </li>
          <li>
            <p>
              CoSupport Agent integrates with popular CRM systems, such as Zendesk or Freshdesk;
              integration with a custom CRM is also possible;
            </p>
          </li>
          <li>
            <p>
              CoSupport BI integrates with business communication tools, such as Slack or Microsoft
              Teams; on the back-end part, it integrates with all possible sources of data,
              including databases, internal documentation, knowledge bases, and others.
            </p>
          </li>
        </ul>
        <br />
        <p>
          Not sure if CoSupport AI integrates with your tools? Contact our team, and we’ll help find
          a solution.
        </p>
      </div>
    ),
  },
  {
    question: 'How do I get a demo of any of CoSupport AI’s products?',
    answer: (
      <div>
        <p>You can see us demonstrate your chosen solution by:</p>
        <ul className="list-disc pl-5">
          <li>
            <p>Leave your email address and the demo request in the chat;</p>
          </li>
          <li>
            <p>
              Submit the{' '}
              <Link
                href={PATH_DEMO.ROOT}
                className="underline-offset-1 underline !text-ebony opacity-70"
                prefetch={false}
              >
                <b>
                  <i>Demo Request Form</i>
                </b>
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>
          Email CoSupport AI at{' '}
          <a className="!text-ebony underline" href="mailto:contact@cosupport.ai">
            contact@cosupport.ai
          </a>
          .
        </p>
      </div>
    ),
  },
];
