import React from 'react';
import {
  PATH_AGENT,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_DEMO,
  PRODUCT_DEMO,
} from '@/constants/spa-routes';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { PRODUCTS } from '@/constants/products';
import Link from 'next/link';

type TextBlockProps = {
  currentSlide: number;
};

const TEXTS = [
  {
    label: 'AI Agent',
    description:
      'Autonomous AI that handles customer inquiries independently with up to 99% response accuracy.',
    linkText: 'Learn more about CoSupport Customer →',
    link: PATH_CUSTOMER.ROOT,
    buttonLink: PATH_DEMO.CUSTOMER_DEMO,
    buttonText: 'Chat with AI',
    type: PRODUCTS.CUSTOMER,
  },
  {
    label: 'AI Assistant',
    description: (
      <>
        Generates ready-to-go reply suggestions <br className="max-md:hidden" /> for support agents.
      </>
    ),
    linkText: 'Learn more about CoSupport Agent →',
    link: PATH_AGENT.ROOT,
    buttonLink: PRODUCT_DEMO.AGENT,
    buttonText: 'Try a Demo',
    type: PRODUCTS.AGENT,
  },
  {
    label: 'AI Business Intelligence',
    description: 'Provides insights and recommendations based on customer interactions.',
    linkText: 'Learn more about CoSupport BI →',
    link: PATH_BI.ROOT,
    buttonLink: PRODUCT_DEMO.BI,
    buttonText: 'Request a Demo',
    type: PRODUCTS.BI,
  },
];

export const TextBlock = ({ currentSlide }: TextBlockProps) => {
  return (
    <div className="flex flex-col lg:gap-2.5-vw gap-2">
      <Typography
        $breakpoint="1024"
        as="h3"
        $type="Heading1"
        $color={COLORS.WHITE}
        $weight="700"
        $lineHeight={{ sm: '140%' }}
        className="text-start !pr-2 font-formula"
      >
        {TEXTS[currentSlide].label}
      </Typography>
      <Typography
        $type="Heading4"
        $color={COLORS.WHITE}
        $weight="400"
        className="md:max-w-[37.5vw] font-jamjuree opacity-70"
      >
        {TEXTS[currentSlide].description}
      </Typography>
      <Link href={TEXTS[currentSlide].link} prefetch={false}>
        <Typography
          $type="Heading4"
          $color={COLORS.WHITE}
          $weight="600"
          className="font-jamjuree italic underline"
        >
          {TEXTS[currentSlide].linkText}
        </Typography>
      </Link>
      <div className="flex max-md:w-full flex-wrap mt-6">
        <GreenButton
          linkBtnClassName="max-md:w-full"
          url={TEXTS[currentSlide].buttonLink}
          className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
        >
          <Typography $type="Heading4" $weight="700">
            {TEXTS[currentSlide].buttonText}
          </Typography>
        </GreenButton>
      </div>
    </div>
  );
};
