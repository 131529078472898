import { PATENT_LINK } from '@/constants/links';
import React from 'react';

export const PATENT_DESCRIPTION_ITEMS = [
  {
    id: 0,
    title: 'Patented AI Architecture',
    description: (
      <>
        Officially recognized by the{' '}
        <a
          href={PATENT_LINK}
          target="_blank"
          rel="noreferrer"
          className="underline underline-offset-1 cursor-pointer"
        >
          U.S. Patent Office
        </a>
        , our specialized generative customer service AI technology ensures highly accurate and
        efficient responses tailored to your business needs.
      </>
    ),
  },
  {
    id: 1,
    title: 'Faster Response Times',
    description:
      'CoSupport’s AI-powered customer service solutions reply in milliseconds for individual queries and within 5 seconds for complex message threads — significantly faster than industry standards.',
  },
  {
    id: 2,
    title: 'Cost-Effective',
    description:
      'Resource-efficient AI for customer service models reduces operational costs through transparent pricing, providing a fully functional AI solution.',
  },
  {
    id: 3,
    title: 'Enhanced Data Security',
    description:
      'Client-specific proprietary data is securely stored and processed without external databases, reducing vulnerability and minimizing data leakage risks. Our customer service AI platform learns quickly, further protecting your data.',
  },
];
