import React, { ReactElement } from 'react';
import { Typography } from '@/components/Typography/Typography';
import { AccordionComponent } from '@/components/AccordionComponent/AccordionComponent';
import { COLORS } from '@/constants/colors';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import {
  PATH_AGENT,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_DEMO,
  PRODUCT_DEMO,
} from '@/constants/spa-routes';
import { MultipleAccordionComponent } from '@/components/AccordionComponent/MultipleAccordionComponent';
import Link from 'next/link';

type Props = {
  handleItemsChange: (value: string | number) => void;
  isMdUp: boolean;
};

const MessageIcon = () => {
  return (
    <svg
      className="md:min-w-15-vw md:min-h-15-vw min-w-[70px] min-h-[70px] md:max-w-15-vw md:max-h-15-vw max-w-[70px] max-h-[70px]"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#05050D" />
      <path
        d="M41 19L41 19.75L41 19ZM20 19L20 18.25L20 19ZM27.2989 39.1598L27.8319 38.6322L27.6117 38.4098L27.2989 38.4098L27.2989 39.1598ZM30.1106 42L29.5776 42.5277L30.1106 43.0661L30.6436 42.5277L30.1106 42ZM32.9223 39.1598L32.9223 38.4098L32.6094 38.4098L32.3893 38.6322L32.9223 39.1598ZM41 19.75C41.6904 19.75 42.25 20.3096 42.25 21L43.75 21C43.75 19.4812 42.5188 18.25 41 18.25L41 19.75ZM20 19.75L41 19.75L41 18.25L20 18.25L20 19.75ZM18.75 21C18.75 20.3096 19.3096 19.75 20 19.75L20 18.25C18.4812 18.25 17.25 19.4812 17.25 21L18.75 21ZM18.75 37.1598L18.75 21L17.25 21L17.25 37.1598L18.75 37.1598ZM20 38.4098C19.3096 38.4098 18.75 37.8502 18.75 37.1598L17.25 37.1598C17.25 38.6786 18.4812 39.9098 20 39.9098L20 38.4098ZM27.2989 38.4098L20 38.4098L20 39.9098L27.2989 39.9098L27.2989 38.4098ZM30.6436 41.4724L27.8319 38.6322L26.7659 39.6875L29.5776 42.5277L30.6436 41.4724ZM32.3893 38.6322L29.5776 41.4724L30.6436 42.5277L33.4553 39.6875L32.3893 38.6322ZM41 38.4098L32.9223 38.4098L32.9223 39.9098L41 39.9098L41 38.4098ZM42.25 37.1598C42.25 37.8502 41.6904 38.4098 41 38.4098L41 39.9098C42.5188 39.9098 43.75 38.6786 43.75 37.1598L42.25 37.1598ZM42.25 21L42.25 37.1598L43.75 37.1598L43.75 21L42.25 21Z"
        fill="white"
      />
      <rect
        width="3.5"
        height="3.5"
        rx="1.75"
        transform="matrix(-1 -8.75806e-08 0.0032724 -0.999995 26 31.1348)"
        fill="white"
      />
      <rect
        width="3.5"
        height="3.5"
        rx="1.75"
        transform="matrix(-1 -8.75806e-08 0.0032724 -0.999995 32.2344 31.1351)"
        fill="white"
      />
      <rect
        width="3.5"
        height="3.5"
        rx="1.75"
        transform="matrix(-1 -8.75806e-08 0.00269915 -0.999996 38.8281 31.1348)"
        fill="white"
      />
    </svg>
  );
};
const HeadphonesIcon = () => {
  return (
    <svg
      className="md:min-w-15-vw md:min-h-15-vw min-w-[70px] min-h-[70px] md:max-w-15-vw md:max-h-15-vw max-w-[70px] max-h-[70px]"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#05050D" />
      <path
        d="M38.125 34.8043V26.1311C38.125 21.6404 34.4846 18 29.9939 18V18C25.5033 18 21.8629 21.6404 21.8629 26.1311V37.1504C21.8629 38.5064 22.9621 39.6056 24.318 39.6056H27.5193"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M21.375 26.5022H20.0031C19.1746 26.5022 18.5031 27.1738 18.5031 28.0022V32.8045C18.5031 33.6329 19.1746 34.3045 20.0031 34.3045H21.375V26.5022Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M38.625 34.3053L39.9969 34.3053C40.8254 34.3053 41.4969 33.6337 41.4969 32.8053L41.4969 28.003C41.4969 27.1746 40.8254 26.503 39.9969 26.503L38.625 26.503L38.625 34.3053Z"
        fill="white"
        stroke="white"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="2"
        transform="matrix(-1 0 0 1 31.1641 37.2054)"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
const StarIcon = () => {
  return (
    <svg
      className="md:min-w-15-vw md:min-h-15-vw min-w-[70px] min-h-[70px] md:max-w-15-vw md:max-h-15-vw max-w-[70px] max-h-[70px]"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#05050D" />
      <path
        d="M29.3367 18.5878C29.7153 18.2523 30.2847 18.2523 30.6633 18.5878L33.0088 20.6665C33.1984 20.8346 33.4448 20.9244 33.6982 20.9177L36.8289 20.8359C37.3339 20.8226 37.7695 21.1882 37.8441 21.6879L38.3077 24.7943C38.3451 25.0444 38.4757 25.2711 38.6735 25.4287L41.1272 27.3844C41.5216 27.6988 41.6202 28.2578 41.357 28.6881L39.7183 31.3677C39.5865 31.5832 39.5412 31.8407 39.5914 32.0883L40.2165 35.1676C40.3169 35.6623 40.033 36.1542 39.5544 36.3147L36.5828 37.3111C36.3428 37.3916 36.1422 37.5601 36.0216 37.7827L34.5264 40.5411C34.2854 40.9856 33.7506 41.1804 33.2802 40.9948L30.367 39.8454C30.1312 39.7523 29.8688 39.7523 29.633 39.8454L26.7198 40.9948C26.2494 41.1804 25.7146 40.9856 25.4736 40.5411L23.9784 37.7827C23.8578 37.5601 23.6572 37.3916 23.4172 37.3111L20.4456 36.3147C19.967 36.1542 19.6831 35.6623 19.7835 35.1676L20.4086 32.0883C20.4588 31.8407 20.4135 31.5832 20.2817 31.3677L18.643 28.6881C18.3798 28.2578 18.4784 27.6988 18.8728 27.3844L21.3265 25.4287C21.5243 25.2711 21.6549 25.0444 21.6923 24.7943L22.1559 21.6879C22.2305 21.1882 22.6661 20.8226 23.1711 20.8359L26.3018 20.9177C26.5552 20.9244 26.8016 20.8346 26.9912 20.6665L29.3367 18.5878Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M29.9991 24.3157L31.9202 28.1585L35.7638 30.0804L31.9202 32.0022L29.9991 35.8451L28.078 32.0022L24.2344 30.0804L28.078 28.1585L29.9991 24.3157Z"
        fill="white"
      />
    </svg>
  );
};

const accordionItems: {
  id: string;
  triggerTitle: ReactElement | string;
  content: ReactElement | string;
  img?: string;
  isTriggerTitleNode?: boolean;
  isContentNode?: boolean;
}[] = [
  {
    id: '0',
    isTriggerTitleNode: true,
    isContentNode: true,
    triggerTitle: (
      <div className="flex md:justify-start md:items-start items-center md:gap-5.5 gap-2 max-md:flex-col">
        <MessageIcon />
        <Typography
          $type="PMedium"
          $color={COLORS.WHITE}
          $weight="400"
          className="font-jamjuree max-md:text-center text-left"
        >
          <span className="opacity-70">
            Automate routine queries with 99% response accuracy. See how
          </span>{' '}
          <Link href={PATH_CUSTOMER.ROOT} prefetch={false} className="underline !text-white">
            <span className="italic opacity-100 font-semibold">CoSupport Customer</span>
          </Link>{' '}
          <span className="opacity-70"> handles inquiries autonomously across channels</span>
        </Typography>
      </div>
    ),
    img: '/img/main-page/see-cosupport/see-customer-mobile-new.webp',
    content: (
      <div className="flex max-md:w-full flex-wrap justify-center">
        <GreenButton
          linkBtnClassName="max-md:w-full"
          url={PATH_DEMO.CUSTOMER_DEMO}
          className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
        >
          <Typography $type="Heading4" $weight="700">
            Try Real AI
          </Typography>
        </GreenButton>
      </div>
    ),
  },
  {
    id: '1',
    isTriggerTitleNode: true,
    isContentNode: true,
    triggerTitle: (
      <div className="flex md:justify-start md:items-start items-center md:gap-5.5 gap-2 max-md:flex-col">
        <HeadphonesIcon />
        <Typography
          $type="PMedium"
          $color={COLORS.WHITE}
          $weight="400"
          className="font-jamjuree max-md:text-center text-left"
        >
          <span className="opacity-70">
            Enhance agent productivity with instant reply suggestions. Discover how
          </span>{' '}
          <Link href={PATH_AGENT.ROOT} prefetch={false} className="underline !text-white">
            <span className="italic opacity-100 font-semibold">CoSupport Agent</span>
          </Link>{' '}
          <span className="opacity-70"> can optimize your support workflow.</span>
        </Typography>
      </div>
    ),
    img: '/img/main-page/see-cosupport/see-agent-mobile.webp',
    content: (
      <div className="flex max-md:w-full flex-wrap justify-center">
        <GreenButton
          linkBtnClassName="max-md:w-full"
          url={PRODUCT_DEMO.AGENT}
          className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
        >
          <Typography $type="Heading4" $weight="700">
            Try A Demo
          </Typography>
        </GreenButton>
      </div>
    ),
  },
  {
    id: '2',
    isTriggerTitleNode: true,
    isContentNode: true,
    triggerTitle: (
      <div className="flex md:justify-start md:items-start items-center md:gap-5.5 gap-2 max-md:flex-col">
        <StarIcon />
        <Typography
          $type="PMedium"
          $color={COLORS.WHITE}
          $weight="400"
          className="font-jamjuree max-md:text-center text-left"
        >
          <span className="opacity-70">Unlock AI-driven insights and analytics. Explore how</span>{' '}
          <Link href={PATH_BI.ROOT} prefetch={false} className="underline !text-white">
            <span className="italic opacity-100 font-semibold">CoSupport BI</span>
          </Link>{' '}
          <span className="opacity-70"> provides business recommendations and data analysis.</span>
        </Typography>
      </div>
    ),
    img: '/img/main-page/see-cosupport/see-bi-mobile.webp',
    content: (
      <div className="flex max-md:w-full flex-wrap justify-center">
        <GreenButton
          linkBtnClassName="max-md:w-full"
          url={PRODUCT_DEMO.BI}
          className="whitespace-nowrap max-md:w-auto max-md:flex-grow"
        >
          <Typography $type="Heading4" $weight="700">
            Try A Demo
          </Typography>
        </GreenButton>
      </div>
    ),
  },
];

export const AccordionPart = ({ handleItemsChange, isMdUp }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center md:gap-6-vw gap-5 z-10">
      <div className="md:max-w-[36.528vw] flex flex-col h-full md:gap-8-vw gap-8 lg:py-14-vw">
        {isMdUp ? (
          <AccordionComponent
            accordionItemClassName="border-[#FFFFFF1A]"
            accordionTriggerClassName="[&>#trigger-icon]:hidden md:py-10-vw py-6"
            contentClassName="md:pb-10-vw pb-8"
            imageFit
            accordionItems={accordionItems}
            handleItemsChange={handleItemsChange}
          />
        ) : (
          <MultipleAccordionComponent
            autoplay={false}
            defaultValue={accordionItems.map((el) => el.id)}
            accordionItemClassName="border-[#FFFFFF1A]"
            accordionTriggerClassName="[&>#trigger-icon]:hidden md:py-10-vw pb-6 pt-8"
            contentClassName="md:pb-10-vw pb-8"
            imageFit
            accordionItems={accordionItems}
            handleItemsChange={handleItemsChange}
          />
        )}
      </div>
    </div>
  );
};
